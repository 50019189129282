import React,{useEffect} from "react"
import Layout from "../components/layout";
import "aos/dist/aos.css";

import CareersIntro from "@components/careers-intro/careers-intro";
import Valuation from "@components/valuation/valuation";

const CareersDetail = (props) => {
  let AOS;
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
      disable: 'mobile'
    });
  }, [])
  return(
    <div className="careers-detail">
      <Layout classAttribute={'career-detail-template'}>
        <CareersIntro slug={props.detailpage && props.detailpage.item}  />
      </Layout>
    </div>
  )
}

export default CareersDetail