import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import Intro from '@components/intro/intro';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Logo } from '@components/icon/icon';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import CareersForm from "../../components/forms/career";
import SEO from "../../components/seo"
import Sticky from 'react-stickynode';
import Sidebar from '@components/sidebar/sidebar';
import "./styles/_index.scss";
/**
 * Components
 */
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';

const SINGLE_TEAM = gql`
query GetCareersDetails($URL: String!) {
  careers(where:{URL: $URL}) {
    Caption 
    Description
    Job_Opening
    Meta_Description
    Meta_Title
    URL
    id
    Location
  }
}
`;

function gotohash(event) {
  var thishash = event;
  $('html, body').animate({
    scrollTop: $(thishash).offset().top - 120
  }, 1000);
}

function CareersIntro(props) {
  const { loading, error, data } = useQuery(SINGLE_TEAM, {
    variables: { URL: props.slug }
  });

  if (loading) return (
    <div className="static-details gql-loading-screen">
      <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
      </Container>
    </div>);

  return (
    <>
      {data.careers && data.careers.map((node, key) => {
        return <>
          <SEO title={`${node.Job_Opening} Jobs`} description={`Looking for ${node.Job_Opening} job? Submit you CV with us, the top estate agent jobs are now open in Anthony Pepe.`} />
          <div className="team-details team-details-view">
            <Breadcrumbs alias="careers" detailTitle={node.Job_Opening} />
            <div className="careers-details spacing-bottom-only">
              <Container>
                <Row>
                  <Col xl={8}>
                    <div className="static-intro">
                      <Intro>
                        <div className="gudies-listing">
                          <h1 className="spacing-40 mb-0">{node.Job_Opening} Jobs</h1>
                          <p>{node.Meta_Description}</p>
                          <div className="static-text">
                            <div className="job-top">
                              <Row>
                                <Col lg={6} className="job-text"><strong>Location :</strong><br /> {node.Location}</Col>
                                <Col lg={6} className="job-text"><strong>Salary :</strong><br /> {ReactHtmlParser(node.Caption)}</Col>
                              </Row>
                            </div>
                          </div>

                          <div className="details-para">
                            <p>{ReactHtmlParser(node.Description)}</p>
                          </div>

                          <Form className="apply-job" id="apply-section">
                            <Container>
                              <Row>
                                <Col lg={12}>
                                  <h4 className="spacing-bottom-32">
                                    Apply for this Job
                                  </h4>
                                  <p>
                                    If you’re interested in this position please fill in the form.
                                  </p>
                                  <CareersForm jobtitle={node.Job_Opening} />

                                </Col>
                              </Row>
                            </Container>
                          </Form>
                        </div>
                      </Intro>
                    </div>
                  </Col>

                  <Col xl={4}> 
                      <Sticky top={200} bottomBoundary='.apply-job' enableTransforms={false}>
                        <Sidebar className={`wave`}>
                          <h5 className="spacing-bottom-32 white">Interested?</h5>
                          <p className="spacing-bottom-32 white">You will be joining an outstanding, driven and supportive team, with high expectations, high rewards and the opportunity to carve a real career.</p>
                          <div className="btn-wrap">
                            <button onClick={() => gotohash('#apply-section')} className="btn btn-success" type="submit">
                              Apply for this job
                            </button>
                          </div>
                        </Sidebar>
                      </Sticky> 
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </>
      })}
    </>
  )
}

export default CareersIntro
